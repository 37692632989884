<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-modal
      id="modal-disapprove-invoice"
      ref="modalDisapproveInvoice"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Factuur afwijzen"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refDisapproveInvoiceObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handelDisapproveInvoice)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="denyReason"
                rules="required"
              >
                <b-form-group>
                  <label>Reden</label>
                  <b-form-textarea
                    id="denyReason"
                    v-model="disapproveInvoiceData.denyReason"
                    rows="2"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="showInvoiceDisapprovalSpinner"
                  small
                  class="mr-1"
                />
                Afwijzen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-pdf-viewer"
      ref="modalPdfViewer"
      title=""
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      :hide-footer="true"
      size="lg"
    >
      <pdf-viewer/>
    </b-modal>
    <b-modal
      id="modal-change-paycheck-period-invoice"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Verloningsperiode wijzigen"
      ref="modalChangePaycheckPeriodInvoice"
      :hide-footer="true"
      no-close-on-backdrop
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handleChangePaycheckPeriode)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Verloningsperiode"
                rules="required"
              >
                <b-form-group
                  :state="getValidationState(validationContext)"
                >
                  <label >Selecteer een verloningsperiode</label>
                  <v-select
                    id="paycheckPeriodSelected"
                    v-model="paycheckPeriodSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paycheckPeriod"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  small
                  class="mr-1"
                  v-if="showChangePaycheckPeriodSpinner"
                />
                Aanvragen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoicesListTable"
        class="position-relative"
        :items="fetchInvoices"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        style="min-height: 200px"
      >
        <template #cell(select)="data">

          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
            v-if="data.item.status === 20 && (!data.item.hasCollection && data.item.hasCollection === 0)"
            :key="data.item.id"
            v-model="selectedInvoices" :value="data.item.id"
          >
          </b-form-checkbox>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          {{ data.value?data.value:'' }}
        </template>

        <template #cell(collectionID)="data">
          #{{ data.value }}
        </template>

        <template #cell(status)="data">
          <b-badge
            :id="`invoice-row-${data.item.id}-status-error`"
            :variant="resolveTypeVariant(data.value).variant"
          >
            {{ resolveTypeVariant(data.value).status }} {{ data.item.hasCollection && userData.type === 2 ?` - Verzamelfactuur: ${data.item.collectionID}`:'' }}
          </b-badge>
          <b-tooltip
            v-if="data.item.status === 50 || data.item.status === 51"
            :target="`invoice-row-${data.item.id}-status-error`"
            placement="left"
          >
            <p
              v-if="data.item.status === 50"
              class="mb-0"
            >
              Afgekeurd door freelancer: {{ data.item.denyReasonFreelancer ? data.item.denyReasonFreelancer : 'Zonder opgaaf van reden' }}
            </p>
            <p
              v-if="data.item.status === 51"
              class="mb-0"
            >
              Afgekeurd door organisatie: {{ data.item.denyReasonCompany ? data.item.denyReasonCompany : 'Zonder opgaaf van reden' }}
            </p>
            <p
              v-if="data.item.status === 52"
              class="mb-0"
            >
              Afgekeurd door Payrite: {{ data.item.denyReasonAdmin ? data.item.denyReasonAdmin : 'Zonder opgaaf van reden' }}
            </p>
          </b-tooltip>
        </template>

        <template #cell(companyID)="data">
          {{ data.item.company.name }}
        </template>
        <template #cell(commissionAmount)="data">
          &euro; {{parseFloat(data.value).toFixed(2)}}
          <feather-icon
              :id="`paycheck-popover-row-${data.item.id}-invoice-icon`"
              class="cursor-pointer"
              icon="InfoIcon"
              size="16"
              style="color: #29625b;"
          />
          <b-popover
              :target="`paycheck-popover-row-${data.item.id}-invoice-icon`"
              placement="right"
              triggers="hover"
          >
            <template #title>Commissieinformatie</template>
            Commissie PayRite: &euro; {{parseFloat(data.item.commissionAmount).toFixed(2)}}<br>
            Commissie Percentage: {{ data.item.commissionPercentage }} %<br>
            Company betaald fee: {{ data.item.companyPaysFee ? "Ja" : "Nee" }}<br>
            Freelancercommissie: &euro; {{parseFloat(data.item.freelancerCommissionAmount).toFixed(2)}} ({{ data.item.commissionPercentageFreelancer }} %)
            Companycommissie: &euro; {{parseFloat(data.item.companyCommissionAmount).toFixed(2)}} ({{ data.item.commissionPercentageCompany }} %)
          </b-popover>
        </template>
        <template #cell(totalAmount)="data">
          &euro; {{parseFloat(data.value).toFixed(2)}}
          <feather-icon
              :id="`paycheck-amount-invoice-row-${data.item.id}-invoice-icon`"
              class="cursor-pointer"
              icon="InfoIcon"
              size="16"
              style="color: #29625b;"
          />
          <b-popover
              :target="`paycheck-amount-invoice-row-${data.item.id}-invoice-icon`"
              placement="right"
              triggers="hover"
          >
            <template #title>Bedraginformatie</template>
            Factuurlijnen: &euro; {{parseFloat(data.item.lineAmount).toFixed(2)}}<br>
            Onkosten: &euro; {{parseFloat(data.item.expenseAmount).toFixed(2)}}<br>
            Onkosten gefactureerd: &euro; {{parseFloat(data.item.expenseAmountInvoiced).toFixed(2)}}<br>
            Ex BTW: &euro; {{parseFloat(data.item.subAmount).toFixed(2)}}<br>
            BTW: &euro; {{parseFloat(data.item.vatAmount).toFixed(2)}}<br>
            Incl.: &euro; {{parseFloat(data.item.totalAmount).toFixed(2)}}<br>
            Facturabel: &euro; {{parseFloat(data.item.invoiceableSubAmount).toFixed(2)}}<br>
            Uitbetaalbaar: &euro; {{parseFloat(data.item.paycheckAmount).toFixed(2)}}<br>
          </b-popover>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
<!--            <b-dropdown-item-->
<!--              v-if="(!data.item.hasCollection && data.item.hasCollection === 0) && $can('read', 'account-company') && data.item.payURL !== null && (!data.item.isPaid || data.item.isPaid === 0) &&data.item.status === 20"-->
<!--              :id="`pay-url-row-${data.item.id}-invoice`"-->
<!--              @click="goToURL(data.item.payURL)"-->
<!--            >-->
<!--              <feather-icon-->
<!--                :id="`pay-url-row-${data.item.id}-invoice-icon`"-->
<!--                class="cursor-pointer"-->
<!--                icon="CreditCardIcon"-->
<!--                size="16"-->
<!--                style="color: #29625b;"-->
<!--              />-->
<!--              Factuur betalen-->
<!--            </b-dropdown-item>-->
            <b-dropdown-item
              v-if="$can('read', 'account-freelancer') && (data.item.paycheckProcessed === null || data.item.paycheckProcessed === 0)"
              :id="`paycheck-period-row-${data.item.id}-invoice`"
              @click="showModalPaycheckPeriode(data.item.id)"
            >
              <feather-icon
                :id="`paycheck-period-row-${data.item.id}-invoice-icon`"
                class="cursor-pointer"
                icon="ClockIcon"
                size="16"
                style="color: #29625b;"
              />
              Verloningsperiode wijzigen
            </b-dropdown-item>
            <b-dropdown-item
              :id="`approve-row-${data.item.id}-invoice`"
              v-if="data.item.status === 10"
              @click="confirmApproveInvoice(data.item.id)"
            >
              <feather-icon
                :id="`approve-row-${data.item.id}-invoice-icon`"
                class="cursor-pointer"
                icon="CheckCircleIcon"
                size="16"
                style="color: #29625b;"
              />
              Accepteren
            </b-dropdown-item>
            <b-dropdown-item
              :id="`decline-row-${data.item.id}-invoice`"
              v-if="data.item.status === 10"
              @click="confirmDisapproveInvoice(data.item.id)"
            >
              <feather-icon
                :id="`decline-row-${data.item.id}-invoice-icon`"
                class="cursor-pointer"
                icon="XCircleIcon"
                size="16"
                style="color: #29625b;"
              />
              Afkeuren
            </b-dropdown-item>
            <b-dropdown-item
              :id="`pdf-row-${data.item.id}-preview`"
              @click="viewDocument(data.item)"
            >
              <feather-icon
                :id="`pdf-row-${data.item.id}-preview-icon`"
                class="cursor-pointer"
                icon="EyeIcon"
                size="16"
                style="color: #29625b;"
              />
              Bekijken
            </b-dropdown-item>
            <b-dropdown-item
              :id="`invoices-row-${data.item.id}-download`"
              :href="`${axiosDefaultConfig.backendEndPoint}invoices/${data.item.id}/pdf?token=${data.item.pdfToken}`"
              target="_blank"
            >
              <feather-icon
                :id="`invoices-row-${data.item.id}-download-icon`"
                class="cursor-pointer"
                icon="DownloadIcon"
                size="16"
              />
              Downloaden
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalInvoices"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem, BForm, BFormCheckbox, BFormGroup, BFormInvalidFeedback, BFormTextarea,
  BOverlay,
  BPagination,
  BPopover,
  BRow, BSpinner,
  BTable, BTooltip, VBToggle,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import PdfViewer from '@/views/components/pdf-viewer/PdfViewer.vue'
import useInvoicesList from '@/views/pages/invoices/useInvoicesList'
import formValidation from '@core/comp-functions/forms/form-validation'
import invoicesStoreModule from '@/views/pages/invoices/invoicesStoreModule'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    BOverlay,
    PdfViewer,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    BFormGroup,
    BForm,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,
    BTooltip,
    BPopover,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      profileData: {},
      paycheckPeriodSelected: null,
      showChangePaycheckPeriodSpinner: false,
      selectedInvoices: [],
      showCreateInvoiceCollectionSpinner: false,
      disapproveInvoiceData: {
        status: null,
        id: null,
        denyReason: null,
      },
      paycheckPeriod: [],
    }
  },
  beforeMount() {
    this.profileData = JSON.parse(localStorage.getItem('userData'))
    this.getPayPeriods()
  },
  methods: {
    getPayPeriods() {
      store
        .dispatch('app-invoices/fetchPayPeriods')
        .then(response => {
          this.paycheckPeriod = response.data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Verloningsperiode',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    goToURL(url) {
      window.open(url)
    },
    viewDocument(data) {
      this.$store.commit('app/UPDATE_PDF_URL', `${axiosDefaultConfig.backendEndPoint}invoices/${data.id}/pdf?token=${data.pdfToken}`)
      this.$refs.modalPdfViewer.show()
    },
    confirmDisapproveInvoice(id) {
      this.disapproveInvoiceData.id = id
      this.$refs.modalDisapproveInvoice.show()
    },
    handelDisapproveInvoice() {
      this.showInvoiceDisapprovalSpinner = true

      this.disapproveInvoiceData.isAdminApproved = -1
      this.disapproveInvoiceData.status = 52

      store
        .dispatch('app-invoices/approveInvoice', this.disapproveInvoiceData)
        .then(() => {
          this.$refs.modalDisapproveInvoice.hide()
          this.showInvoiceDisapprovalSpinner = false
          this.refetchData()
        })
        .catch(() => {
          this.$refs.modalDisapproveInvoice.hide()
          this.showInvoiceDisapprovalSpinner = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Factuur afwijzen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    confirmApproveInvoice(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, factuur accepteren!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.approveInvoice(id)
        }
      })
    },
    approveInvoice(id) {
      const acceptData = {
        id,
        isAdminApproved: 1,
      }

      store
        .dispatch('app-invoices/approveInvoice', acceptData)
        .then(() => {
          this.refetchData()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Factuur accepteren',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    showModalPaycheckPeriode(id) {
      this.id = id
      this.$refs.modalChangePaycheckPeriodInvoice.show()
    },
    handleChangePaycheckPeriode() {
      this.showChangePaycheckPeriodSpinner = true

      store
        .dispatch('app-invoices/patchInvoice', { paycheckPeriod: this.paycheckPeriodSelected, id: this.id })
        .then(() => {
          this.showChangePaycheckPeriodSpinner = false
          this.$refs.modalChangePaycheckPeriodInvoice.hide()
          this.id = null
          this.refetchData()
        })
        .catch(() => {
          this.showChangePaycheckPeriodSpinner = false
          this.$refs.modalChangePaycheckPeriodInvoice.hide()
          this.id = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Verloningsperiode wijzigen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het aanpassen van de verloningsperiode. Probeer het nogmaals.',
            },
          })
        })
    },
    createInvoiceCollection() {
      this.showCreateInvoiceCollectionSpinner = true
      store
        .dispatch('app-invoices/createInvoiceCollection', this.selectedInvoices)
        .then(() => {
          this.showCreateInvoiceCollectionSpinner = false
          this.selectedInvoices = []
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Verzamelfactuur',
              icon: 'EditIcon',
              variant: 'success',
              text: 'De verzamelfactuur is met succes aangemaakt.',
            },
          })
        })
        .catch(() => {
          this.showCreateInvoiceCollectionSpinner = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Verzamelfactuur',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het aanmaken van de verzamelfactuur. Probeer later het nog een keer.',
            },
          })
        })
    },
  },
  setup() {
    const INVOICES_APP_STORE_MODULE_NAME = 'app-invoices'

    // Register module
    if (!store.hasModule(INVOICES_APP_STORE_MODULE_NAME)) store.registerModule(INVOICES_APP_STORE_MODULE_NAME, invoicesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICES_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICES_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      sortBy,
      refInvoicesListTable,
      statusFilter,
      organisationFilter,
      resolveTypeVariant,
      statusOptions,
      refetchData,
      showOverlay,
      searchQuery,
      isSortDirDesc,
      fetchInvoices,
    } = useInvoicesList()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      sortBy,
      refInvoicesListTable,
      statusFilter,
      organisationFilter,
      resolveTypeVariant,
      statusOptions,
      refetchData,
      showOverlay,
      searchQuery,
      isSortDirDesc,
      fetchInvoices,
      axiosDefaultConfig,
      required,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
